<template>
  <b-overlay :show="loading" class="mt-5">
    <div v-if="jobData" class="">
      <h3 class="text-dark">Submissions for {{ jobData.title }}</h3>
      <p class="m-0">Job Type: <strong>{{ jobData.job_type }}</strong></p>
      <p>Total submissions: <strong>{{ tableData.length }}</strong></p>
    </div>
    
    <list-table
      :records="tableData"
      :columns="tableColumns"
      :total-records="tableData.length"
      :current-page-number="1"
      :show-search="false"
      :show-export-button="false"
      :show-filter-by-default="false"
      @row-clicked="onRowClicked"
    >
      <template #cell(candidate)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="getValueFromSource(data.item, 'candidate.avatar.path')"
              :text="sentenceCase(avatarText(`${data.item.candidate.full_name}`))"
              :variant="`light-success`"
              :to="{ name: 'single-candidate', params: { id: data.item.candidate._id } }"
            />
          </template>

          <b-link
            :to="{ name: 'single-candidate', params: { id: data.item.candidate.id } }"
            class="font-weight-bold d-block text-nowrap "
          >
            {{ sentenceCase(getValueFromSource(data, 'item.candidate.full_name')) }}
          </b-link>
          <span class="text-muted">CV Score {{ data.item.candidate.cv_score.toFixed(2) }}%</span><br />
        </b-media>
      </template>

      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.status)}`"
          class="text-capitalize"
        >
          {{ getUserStatusText(data.item.status) }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item :to="{ name: 'single-candidate', params: { id: getValueFromSource(data, 'item.candidate._id') } }">
            <feather-icon icon="EyeIcon" />
            <span class="align-middle ml-50">View CV</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </list-table>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ListTable from "@core/components/ListTable/ListTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { get } from "lodash";
import { formatDate } from "@core/utils/utils";
import { BOverlay, BLink, BButton, BModal, BRow, BCol, BTooltip, BAvatar, BMedia, BBadge, BDropdown, BDropdownItem, } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BModal,
    BMedia,
    BBadge,
    BButton,
    vSelect,
    BAvatar,
    BTooltip,
    BOverlay,
    ListTable,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      jobData: null,
      tableData: [],

      tableColumns: [
        { key: 'candidate', label: '', sortable: false },
        { key: 'candidate.email', label: 'Email', sortable: false },
        { key: 'candidate.phone', label: 'Phone', sortable: false },
        { key: 'status', sortable: false },
        { key: 'created', label: 'Date', sortable: false, formatter: val => `${formatDate(val, 'MMM, yyyy')}`, class: 'width-200' },
        { key: 'actions' },
      ]
    }
  },
  computed: {
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { job_id } = this.$route.params;
        const request = await this.useJwt().providerService.fetchJobApplications(job_id);
        const { data } = request.data;
        console.log('data', data)
        this.jobData = data.job;
        this.tableData = data.applications
        // this.tableData = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    onRowClicked(rowItem){
      this.$router.push({ name: 'single-candidate', params: { id: rowItem.candidate._id } })
      .catch(() => {})
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';

#add-or-update-event-center {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

